import { merge } from 'lodash';

import { alpha, darken, emphasize, getContrastRatio, lighten } from './colorManipulator';
import { palette } from './palette';
import { DeepPartial, ThemeRichColor } from './types';

/** @internal */
export type ThemeColorsMode = 'light' | 'dark';

/** @internal */
export interface ThemeColorsBase<TColor> {
  mode: ThemeColorsMode;

  primary: TColor;
  secondary: TColor;
  info: TColor;
  error: TColor;
  success: TColor;
  warning: TColor;

  text: {
    primary: string;
    secondary: string;
    disabled: string;
    link: string;
    /** Used for auto white or dark text on colored backgrounds */
    maxContrast: string;
  };

  background: {
    /** Dashboard and body background */
    canvas: string;
    /** Primary content pane background (panels etc) */
    primary: string;
    /** Cards and elements that need to stand out on the primary background */
    secondary: string;
  };

  border: {
    weak: string;
    medium: string;
    strong: string;
  };

  gradients: {
    brandVertical: string;
    brandHorizontal: string;
  };

  action: {
    /** Used for selected menu item / select option */
    selected: string;
    /**
     * @alpha (Do not use from plugins)
     * Used for selected items when background only change is not enough (Currently only used for FilterPill)
     **/
    selectedBorder: string;
    /** Used for hovered menu item / select option */
    hover: string;
    /** Used for button/colored background hover opacity */
    hoverOpacity: number;
    /** Used focused menu item / select option */
    focus: string;
    /** Used for disabled buttons and inputs */
    disabledBackground: string;
    /** Disabled text */
    disabledText: string;
    /** Disablerd opacity */
    disabledOpacity: number;
  };

  hoverFactor: number;
  contrastThreshold: number;
  tonalOffset: number;
}

export interface ThemeHoverStrengh {}

/** @beta */
export interface ThemeColors extends ThemeColorsBase<ThemeRichColor> {
  /** Returns a text color for the background */
  getContrastText(background: string, threshold?: number): string;
  /* Brighten or darken a color by specified factor (0-1) */
  emphasize(color: string, amount?: number): string;
}

function hexToRgb(hex: string) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the hex color
  let bigint = parseInt(hex, 16);

  // If the hex code is invalid, return null
  if (isNaN(bigint)) {
    return null;
  }

  // Extract the red, green, and blue values
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return `${r}, ${g}, ${b}`;
}

function updatedHexColor(hex: string, amount: number): string {
  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Calculate the adjustment value
  let adjust = 255 * amount;

  // Increase each component by the adjustment value
  r = Math.min(255, r + adjust);
  r = Math.max(0, r);
  g = Math.min(255, g + adjust);
  g = Math.max(0, g);
  b = Math.min(255, b + adjust);
  b = Math.max(0, b);

  // Convert back to hex and return
  return `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g).toString(16).padStart(2, '0')}${Math.round(b)
    .toString(16)
    .padStart(2, '0')}`;
}

const nortechPalette = {
  primaryColor:
    (typeof process !== 'undefined' && process.env.PRIMARY_COLOR) ||
    (typeof window !== 'undefined' && window.nortechCustomization?.primaryColor),
  primaryColorDark:
    (typeof process !== 'undefined' && process.env.PRIMARY_COLOR_DARK) ||
    (typeof window !== 'undefined' && window.nortechCustomization?.primaryColorDark),
  warnColor:
    (typeof process !== 'undefined' && process.env.WARN_COLOR) ||
    (typeof window !== 'undefined' && window.nortechCustomization?.warnColor),
  warnColorDark:
    (typeof process !== 'undefined' && process.env.WARN_COLOR_DARK) ||
    (typeof window !== 'undefined' && window.nortechCustomization?.warnColorDark),
  backgroundColor:
    (typeof process !== 'undefined' && process.env.BACKGROUND_COLOR) ||
    (typeof window !== 'undefined' && window.nortechCustomization?.backgroundColor),
  backgroundColorDark:
    (typeof process !== 'undefined' && process.env.BACKGROUND_COLOR_DARK) ||
    (typeof window !== 'undefined' && window.nortechCustomization?.backgroundColorDark),
  fontColor:
    (typeof process !== 'undefined' && process.env.FONT_COLOR) ||
    (typeof window !== 'undefined' && window.nortechCustomization?.fontColor),
  fontColorDark:
    (typeof process !== 'undefined' && process.env.FONT_COLOR_DARK) ||
    (typeof window !== 'undefined' && window.nortechCustomization?.fontColorDark),
};

/** @internal */
export type ThemeColorsInput = DeepPartial<ThemeColorsBase<ThemeRichColor>>;

class DarkColors implements ThemeColorsBase<Partial<ThemeRichColor>> {
  mode: ThemeColorsMode = 'dark';

  // Used to get more white opacity colors
  whiteBase = nortechPalette.fontColorDark ? hexToRgb(nortechPalette.fontColorDark) : '204, 204, 220';

  border = {
    weak: `rgba(${this.whiteBase}, 0.12)`,
    medium: `rgba(${this.whiteBase}, 0.20)`,
    strong: `rgba(${this.whiteBase}, 0.30)`,
  };

  text = {
    primary: `rgb(${this.whiteBase})`,
    secondary: `rgba(${this.whiteBase}, 0.65)`,
    disabled: `rgba(${this.whiteBase}, 0.6)`,
    link: palette.blueDarkText,
    maxContrast: palette.white,
  };

  primary = {
    main: nortechPalette.backgroundColorDark
      ? updatedHexColor(nortechPalette.backgroundColorDark, 0.2)
      : palette.blueDarkMain,
    text: nortechPalette.backgroundColorDark
      ? updatedHexColor(nortechPalette.backgroundColorDark, 0.3)
      : palette.blueDarkText,
    border: nortechPalette.backgroundColorDark
      ? updatedHexColor(nortechPalette.backgroundColorDark, 0.3)
      : palette.blueDarkText,
  };

  secondary = {
    main: `rgba(${this.whiteBase}, 0.10)`,
    shade: `rgba(${this.whiteBase}, 0.14)`,
    transparent: `rgba(${this.whiteBase}, 0.08)`,
    text: this.text.primary,
    contrastText: `rgb(${this.whiteBase})`,
    border: `rgba(${this.whiteBase}, 0.08)`,
  };

  info = this.primary;

  error = {
    main: nortechPalette.warnColorDark ? updatedHexColor(nortechPalette.warnColorDark, -0.2) : palette.redDarkMain,
    text: nortechPalette.warnColorDark ? updatedHexColor(nortechPalette.warnColorDark, -0.1) : palette.redDarkText,
  };

  success = {
    main: nortechPalette.primaryColorDark || palette.greenDarkMain,
    text: nortechPalette.primaryColorDark
      ? updatedHexColor(nortechPalette.primaryColorDark, 0.15)
      : palette.greenDarkText,
  };

  warning = {
    main: nortechPalette.warnColorDark || palette.orangeDarkMain,
    text: nortechPalette.warnColorDark ? updatedHexColor(nortechPalette.warnColorDark, 0.2) : palette.orangeDarkText,
  };

  background = {
    canvas: nortechPalette.backgroundColorDark || palette.gray05,
    primary: nortechPalette.backgroundColorDark
      ? updatedHexColor(nortechPalette.backgroundColorDark, 0.05)
      : palette.gray10,
    secondary: nortechPalette.backgroundColorDark
      ? updatedHexColor(nortechPalette.backgroundColorDark, 0.1)
      : palette.gray15,
  };

  action = {
    hover: `rgba(${this.whiteBase}, 0.16)`,
    selected: `rgba(${this.whiteBase}, 0.12)`,
    selectedBorder: palette.orangeDarkMain,
    focus: `rgba(${this.whiteBase}, 0.16)`,
    hoverOpacity: 0.08,
    disabledText: this.text.disabled,
    disabledBackground: `rgba(${this.whiteBase}, 0.04)`,
    disabledOpacity: 0.38,
  };

  gradients = {
    brandHorizontal: 'linear-gradient(270deg, #F55F3E 0%, #FF8833 100%)',
    brandVertical: 'linear-gradient(0.01deg, #F55F3E 0.01%, #FF8833 99.99%)',
  };

  contrastThreshold = 3;
  hoverFactor = 0.03;
  tonalOffset = 0.15;
}

class LightColors implements ThemeColorsBase<Partial<ThemeRichColor>> {
  mode: ThemeColorsMode = 'light';

  blackBase = nortechPalette.fontColor ? hexToRgb(nortechPalette.fontColor) : '36, 41, 46';

  primary = {
    main: nortechPalette.backgroundColor
      ? updatedHexColor(nortechPalette.backgroundColor, -0.2)
      : palette.blueLightMain,
    border: nortechPalette.backgroundColor
      ? updatedHexColor(nortechPalette.backgroundColor, -0.3)
      : palette.blueLightText,
    text: nortechPalette.backgroundColor
      ? updatedHexColor(nortechPalette.backgroundColor, -0.3)
      : palette.blueLightText,
  };

  text = {
    primary: `rgba(${this.blackBase}, 1)`,
    secondary: `rgba(${this.blackBase}, 0.75)`,
    disabled: `rgba(${this.blackBase}, 0.50)`,
    link: this.primary.text,
    maxContrast: palette.black,
  };

  border = {
    weak: `rgba(${this.blackBase}, 0.12)`,
    medium: `rgba(${this.blackBase}, 0.30)`,
    strong: `rgba(${this.blackBase}, 0.40)`,
  };

  secondary = {
    main: `rgba(${this.blackBase}, 0.08)`,
    shade: `rgba(${this.blackBase}, 0.15)`,
    transparent: `rgba(${this.blackBase}, 0.08)`,
    contrastText: `rgba(${this.blackBase},  1)`,
    text: this.text.primary,
    border: this.border.weak,
  };

  info = {
    main: nortechPalette.backgroundColor
      ? updatedHexColor(nortechPalette.backgroundColor, -0.2)
      : palette.blueLightMain,
    text: nortechPalette.backgroundColor
      ? updatedHexColor(nortechPalette.backgroundColor, -0.3)
      : palette.blueLightText,
  };

  error = {
    main: nortechPalette.warnColor ? updatedHexColor(nortechPalette.warnColor, -0.2) : palette.redLightMain,
    text: nortechPalette.warnColor ? updatedHexColor(nortechPalette.warnColor, -0.1) : palette.redLightText,
    border: nortechPalette.warnColor ? updatedHexColor(nortechPalette.warnColor, -0.1) : palette.redLightText,
  };

  success = {
    main: nortechPalette.primaryColor || palette.greenLightMain,
    text: nortechPalette.primaryColor ? updatedHexColor(nortechPalette.primaryColor, 0.15) : palette.greenLightText,
  };

  warning = {
    main: nortechPalette.warnColor || palette.orangeLightMain,
    text: nortechPalette.warnColor ? updatedHexColor(nortechPalette.warnColor, 0.2) : palette.orangeLightText,
  };

  background = {
    canvas: nortechPalette.backgroundColor || palette.gray90,
    primary: nortechPalette.backgroundColor ? updatedHexColor(nortechPalette.backgroundColor, 0.05) : palette.white,
    secondary: nortechPalette.backgroundColor ? updatedHexColor(nortechPalette.backgroundColor, 0.1) : palette.gray100,
  };

  action = {
    hover: `rgba(${this.blackBase}, 0.12)`,
    selected: `rgba(${this.blackBase}, 0.08)`,
    selectedBorder: palette.orangeLightMain,
    hoverOpacity: 0.08,
    focus: `rgba(${this.blackBase}, 0.12)`,
    disabledBackground: `rgba(${this.blackBase}, 0.04)`,
    disabledText: this.text.disabled,
    disabledOpacity: 0.38,
  };

  gradients = {
    brandHorizontal: 'linear-gradient(90deg, #FF8833 0%, #F53E4C 100%)',
    brandVertical: 'linear-gradient(0.01deg, #F53E4C -31.2%, #FF8833 113.07%)',
  };

  contrastThreshold = 3;
  hoverFactor = 0.03;
  tonalOffset = 0.2;
}

export function createColors(colors: ThemeColorsInput): ThemeColors {
  const dark = new DarkColors();
  const light = new LightColors();
  const base = (colors.mode ?? 'dark') === 'dark' ? dark : light;
  const {
    primary = base.primary,
    secondary = base.secondary,
    info = base.info,
    warning = base.warning,
    success = base.success,
    error = base.error,
    tonalOffset = base.tonalOffset,
    hoverFactor = base.hoverFactor,
    contrastThreshold = base.contrastThreshold,
    ...other
  } = colors;

  function getContrastText(background: string, threshold: number = contrastThreshold) {
    const contrastText =
      getContrastRatio(dark.text.maxContrast, background, base.background.primary) >= threshold
        ? dark.text.maxContrast
        : light.text.maxContrast;
    // todo, need color framework
    return contrastText;
  }

  const getRichColor = ({ color, name }: GetRichColorProps): ThemeRichColor => {
    color = { ...color, name };
    if (!color.main) {
      throw new Error(`Missing main color for ${name}`);
    }
    if (!color.text) {
      color.text = color.main;
    }
    if (!color.border) {
      color.border = color.text;
    }
    if (!color.shade) {
      color.shade = base.mode === 'light' ? darken(color.main, tonalOffset) : lighten(color.main, tonalOffset);
    }
    if (!color.transparent) {
      color.transparent = alpha(color.main, 0.15);
    }
    if (!color.contrastText) {
      color.contrastText = getContrastText(color.main);
    }
    if (!color.borderTransparent) {
      color.borderTransparent = alpha(color.border, 0.25);
    }
    return color as ThemeRichColor;
  };

  return merge(
    {
      ...base,
      primary: getRichColor({ color: primary, name: 'primary' }),
      secondary: getRichColor({ color: secondary, name: 'secondary' }),
      info: getRichColor({ color: info, name: 'info' }),
      error: getRichColor({ color: error, name: 'error' }),
      success: getRichColor({ color: success, name: 'success' }),
      warning: getRichColor({ color: warning, name: 'warning' }),
      getContrastText,
      emphasize: (color: string, factor?: number) => {
        return emphasize(color, factor ?? hoverFactor);
      },
    },
    other
  );
}

interface GetRichColorProps {
  color: Partial<ThemeRichColor>;
  name: string;
}
